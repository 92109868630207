import PushPin from '@mui/icons-material/PushPin';
import { MenuItem } from '@mui/material';
import { useHandlePin } from './hooks/useHandlePin';

const UPGRADE = 'Upgrade to Gondola PRO to pin featured posts';
const UNPIN = 'Unpin from top';
const PIN = 'Pin to top';
interface Props {
  postId: number;
  isPinned: boolean;
  shouldUpgrade?: boolean;
  onShowUpgrade?: (text: string) => void;
  onSuccess?: () => void;
}

export const PinMenuItem = ({
  postId, isPinned, shouldUpgrade, onShowUpgrade, onSuccess,
}: Props) => {
  const toggleText = isPinned ? UNPIN : PIN;
  const onHandlePin = useHandlePin(
    postId,
    isPinned,
    onSuccess,
  );

  const onMenuClick = () => {
    if (shouldUpgrade) {
      if (onShowUpgrade) onShowUpgrade(UPGRADE);
    } else {
      onHandlePin();
    }
  };

  return (
    <MenuItem
      onClick={onMenuClick}
    >
      <PushPin fontSize="small" className="mr-1" />
      {toggleText}
    </MenuItem>
  );
};

import { NETWORK_IDS } from 'lib/constants';

/* Facebook and YouTube videos is broken in many cases 
  We need to fix scraper before we can enable external link
*/
const canLinkToNetwork = (network: number) => (
  network !== NETWORK_IDS.facebook
);

const getExploreAccountUrl = (account: string) => `/explore?postingAccount=${account}`;

const getFacebookPostAccountUrl = (url: string) => {
  const urlObject = new URL(url);
  urlObject.pathname = urlObject.pathname.split('/')[1] || '';
  return urlObject.href;
};

const getPostAccountUrl = (account: string, network: number, url: string, channelId?: string) => {
  switch (network) {
  case NETWORK_IDS.twitter:
    return `https://twitter.com/${account}`;
  case NETWORK_IDS.instagram:
    return `https://instagram.com/${account}`;
  case NETWORK_IDS.facebook:
    return getFacebookPostAccountUrl(url);
  case NETWORK_IDS.youtube:
    if (channelId) {
      return `https://youtube.com/channel/${channelId}`;
    }
    return getExploreAccountUrl(account);
  case NETWORK_IDS.tiktok:
    return `https://www.tiktok.com/@${account}`;
  default:
    return '';
  }
};

export const getPostAccountLinkAttributes = (
  shouldLinkToProfile: boolean,
  showProfile: boolean,
  username: string,
  network: number,
  account: string,
  channelId: string,
  url: string,
) => {
  if (shouldLinkToProfile) {
    if (showProfile) {
      return { href: `/${username}` };
    }

    if (canLinkToNetwork(network)) {
      return {
        href: getPostAccountUrl(account, network, url, channelId),
        target: '_blank',
      };
    }
  }

  return { href: getExploreAccountUrl(account) };
};

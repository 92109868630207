import React, { HTMLProps } from 'react';
import Autolinker from 'autolinker';
import { NETWORKS } from 'lib/constants';

interface Props extends HTMLProps<HTMLDivElement> {
  networkId: number;
  children: string;
}

const getMentionConfig = (networkName: string) => {
  if (networkName === 'twitter' || networkName === 'instagram') {
    return networkName;
  }

  return false;
};

const getHashtagConfig = (networkName: string) => {
  if (networkName === 'twitter' || networkName === 'instagram' || networkName === 'facebook') {
    return networkName;
  }

  return false;
};

export const PostCaption = ({ networkId, children, ...restProps }: Props) => {
  const postNetwork = NETWORKS[networkId];
  const autolinker = new Autolinker({
    newWindow: true,
    mention: getMentionConfig(postNetwork.name),
    hashtag: getHashtagConfig(postNetwork.name),
    replaceFn: (match) => {
      const tag = match.buildTag(); // returns an `Autolinker.HtmlTag` instance for an <a> tag
      tag.addClass('cursor-pointer text-orange hover:text-teal hover:underline');
      return tag;
    },
  });
  const linkedText = autolinker.link(children);
  return <div className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: linkedText }} {...restProps} />;
};

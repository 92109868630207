import React, {
  ReactNode, useState,
} from 'react';
import {
  IconButton, Popover,
} from '@mui/material';
import { MoreVert, Settings } from '@mui/icons-material';

interface Props {
  children?: ReactNode;
  altIcon?: boolean;
}

export function DropdownMenu({ children, altIcon = false }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const toggle = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
      return;
    }
    setAnchorEl(null);
  };

  const close = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="menu"
        aria-haspopup="true"
        onClick={toggle}
        size="small"
      >
        {altIcon ? <Settings /> : <MoreVert />}
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={close}
        PaperProps={{
          sx: {
            pointerEvents: 'auto',
          },
        }}
      >
        {children}
      </Popover>
    </>
  );
}

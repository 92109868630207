import {
  ChatBubbleRounded,
  FavoriteRounded, VisibilityRounded,
} from '@mui/icons-material';
import { abbreviateNum } from 'lib/helpers';
import { RetweetIcon } from 'elements/RetweetIcon';
import { MobileTooltip } from 'components/MobileTooltip';

interface Props {
  likes?: number;
  combinedViews?: number;
  shares?: number;
  comments?: number;
  textColor?: string;
}

export const MatchMetrics = ({
  likes, combinedViews, shares, comments, textColor = 'text-orange',
}: Props) => (
  <MobileTooltip title="Cumulative Engagement" arrow>
    <div className={`flex justify-evenly items-center pt-2 ${textColor}`}>
      {likes && (
        <div>
          <FavoriteRounded fontSize="small" />
          {abbreviateNum(likes, 0)}
        </div>
      )}
      {!!combinedViews && combinedViews > 0 && (
        <div>
          <VisibilityRounded fontSize="small" />
          {abbreviateNum(combinedViews, 0)}
        </div>
      )}
      {shares && (
        <div>
          <RetweetIcon fontSize="small" />
          {abbreviateNum(shares, 0)}
        </div>
      )}
      {comments && (
        <div>
          <ChatBubbleRounded fontSize="small" />
          {abbreviateNum(comments, 0)}
        </div>
      )}
    </div>
  </MobileTooltip>
);

import { imagePostUrl, POST_CARD_IMG_WIDTH } from 'lib/utils/imageUtils';
import { PostCaption } from 'components/PostCaption';
import { POST_TYPES } from 'lib/constants';
import {
  createAltTextForPosts, userIsAdmin,
} from 'lib/helpers';
import {
  useState, useEffect, useRef, MouseEvent,
} from 'react';
import { CardMedia } from '@mui/material';
import { PlayCircleFilledTwoTone as PlayButton, RemoveCircleOutline } from '@mui/icons-material';
import { Post } from 'lib/types';
import { useCurrentUser } from 'lib/contexts/ApplicationState';

interface RenderProps {
  post: Post;
  className?: string;
  fixedSize?: { width: number; height: number };
}

export const PostCardMedia = ({ post, className, fixedSize }: RenderProps) => {
  const [shouldRenderVideo, setShouldRenderVideo] = useState(!!post.videoUrl && !fixedSize);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const imgBgUrl = imagePostUrl(post.thumbnailUrl, {
    width: fixedSize?.width || POST_CARD_IMG_WIDTH,
    height: fixedSize?.height,
    isDead: !post.isLive,
  });
  const currentUser = useCurrentUser();
  const video = useRef<any>();

  useEffect(() => {
    const shouldShowVideo = userIsAdmin(currentUser) && !!post.videoUrl;
    if (!shouldShowVideo) {
      setShouldRenderVideo(false);
    }
  }, [currentUser]);

  const pauseVideo = (e: MouseEvent<HTMLElement>) => {
    setIsVideoPlaying(false);
    video?.current?.pause();
    e.preventDefault();
  };

  const playVideo = (e: MouseEvent<HTMLElement>) => {
    setIsVideoPlaying(true);
    video?.current?.play();
    e.preventDefault();
  };

  const showOverlay = () => {
    if (!post.isLive) {
      return <RemoveCircleOutline fontSize="large" className="absolute bottom-1 left-1 text-white" />;
    }
    if (shouldRenderVideo) {
      return (
        <PlayButton
          onClick={(e: any) => {
            if (!isVideoPlaying) {
              playVideo(e);
            } else {
              pauseVideo(e);
            }
          }}
          sx={{ fontSize: '100px' }}
          className={`transition-opacity duration-300 absolute left-0 right-0 top-0 bottom-0 m-auto z-0 text-white ${isVideoPlaying ? 'opacity-0' : 'opacity-100'}`}
        />
      );
    }
    if ((post.type === POST_TYPES.VIDEO || post.type === POST_TYPES.GIF) && !shouldRenderVideo) {
      return <PlayButton fontSize="large" className="absolute bottom-1 left-1 text-white" />;
    }
    return null;
  };

  if (imgBgUrl) {
    return (
      <div className={className}>
        <CardMedia
          ref={video}
          component={shouldRenderVideo ? 'video' : 'img'}
          alt={createAltTextForPosts(post)}
          image={shouldRenderVideo ? post.videoUrl : undefined}
          controlsList="noDownload"
          src={shouldRenderVideo ? post.videoUrl : imgBgUrl}
          poster={imgBgUrl}
          onError={() => setShouldRenderVideo(false)}
          loop
          playsInline
          muted
          onClick={(e: MouseEvent<HTMLElement>) => {
            if (shouldRenderVideo && isVideoPlaying) {
              pauseVideo(e);
            }
          }}
          suppressHydrationWarning
        />
        {showOverlay()}
      </div>
    );
  }

  return (
    <PostCaption networkId={post.network} className="bg-hintgray p-4 border-t border-b whitespace-pre-wrap">
      {post.caption}
    </PostCaption>
  );
};

import { ReactNode } from 'react';
import { MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useRouter } from 'next/router';

import { isGondolaApp } from 'lib/utils/mobileAppUtils';
import { NETWORKS } from 'lib/constants';
import { Post } from 'lib/types';
import {
  buildPostPath, userIsPro, userIsAdmin,
  buildPostUrl,
} from 'lib/helpers';
import { useCurrentUser } from 'lib/contexts/ApplicationState';
import { useModal } from 'mui-modal-provider';
import { DropdownMenu } from 'components/DropdownMenu';
import { useUpgradeModal } from 'components/UpgradeModal';
import { SaveToConceptModal } from 'components/SaveToConcept/SaveToConceptModal';
import { SaveToProjectListButton } from 'components/SaveToList';
import { PinMenuItem } from './PinMenuItem';

interface Props {
  post: Post;
  isProfileOwner?: boolean;
  showPin?: boolean;
  fetchData?: () => void;
  children?: ReactNode;
}

export const PostCardMenu = ({
  post,
  isProfileOwner,
  showPin,
  fetchData,
  children,
}: Props) => {
  const currentUser = useCurrentUser();

  const shouldUpgrade = !userIsPro(currentUser);
  const { showUpgradeModal } = useUpgradeModal();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const networkName = NETWORKS[post.network].title;
  const postPath = buildPostPath(post);
  const canViewTeamFeatures = userIsAdmin(currentUser) || currentUser?.hasPaidTeam;
  const { showModal } = useModal();

  const copyPostLink = async () => {
    const url = `https://gondola.cc${postPath}`;
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: 'Check out this post on Gondola!',
          url,
        });
      } catch (err) {
        // noop
      }
    } else if (navigator.clipboard) {
      navigator.clipboard.writeText(url);
      enqueueSnackbar('Page copied!', { variant: 'success' });
    }
  };

  const goTo = (href: string) => {
    router.push(href);
  };

  const onAddToConceptClick = () => {
    if (!currentUser || !currentUser.managedAccounts || !currentUser.managedAccounts[0]) {
      return;
    }
    const { teamId } = currentUser.managedAccounts[0];
    const modal = showModal(SaveToConceptModal, {
      teamId,
      onCancel: () => {
        modal.destroy();
      },
      onSave: () => {
        modal.destroy();
      },
      post,
    });
  };

  return (
    <DropdownMenu>
      <MenuItem onClick={() => goTo(postPath)}>
        View post details
      </MenuItem>
      <MenuItem component="a" href={post.url} target="_blank" rel="noopenner noreferrer">
        View on
        {' '}
        {networkName}
      </MenuItem>
      <MenuItem component="a" href={`/visualmatch?url=${buildPostUrl(post)}`}>
        Compare to other post media
      </MenuItem>
      {canViewTeamFeatures && (
        <MenuItem onClick={onAddToConceptClick}>
          Add to concept
        </MenuItem>
      )}
      {userIsAdmin(currentUser) && (
        <MenuItem>
          <SaveToProjectListButton itemId={post.id} itemType="Post" text="Add to project" context="project" />
        </MenuItem>
      )}
      <MenuItem onClick={copyPostLink} className="border-b last:border-b-0 border-lightgray border-solid">
        Copy post link
      </MenuItem>

      {isProfileOwner && (!shouldUpgrade || !isGondolaApp) && (
        <PinMenuItem
          postId={post.id}
          isPinned={showPin || false}
          shouldUpgrade={shouldUpgrade}
          onShowUpgrade={showUpgradeModal}
          onSuccess={fetchData}
        />
      )}
      {children}
    </DropdownMenu>
  );
};

import { useState, useEffect } from 'react';
import {
  Dialog, IconButton,
  Autocomplete, TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useApi } from 'lib/contexts/ApplicationState';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Concept } from 'lib/types/concepts';
import { buildPostUrl } from 'lib/helpers';
import { Post } from 'lib/types';

interface Props {
  onCancel: () => void;
  teamId: number;
  onSave: () => void;
  post: Post;
}

export const SaveToConceptModal = ({
  onCancel, teamId, onSave, post,
}: Props) => {
  const API = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [concepts, setConcepts] = useState<Concept[]>([]);
  const [selected, setSelected] = useState<Concept[]>([]);
  const [addNew, setAddNew] = useState(false);
  const [newTitle, setNewTitle] = useState('');

  useEffect(() => {
    const getConcepts = async () => {
      const c = await API.searchConcepts({ teamId });
      setConcepts(c.data);
    };

    if (!concepts || concepts.length === 0) {
      getConcepts();
    }
  }, [teamId, API]);

  const saveNewConcept = async () => {
    const newConcept = await API.addConcept({
      title: newTitle,
      teamId,
      links: [{ url: buildPostUrl(post), title: 'gondola.cc' }],
    });

    setConcepts([...concepts, newConcept]);
    setSelected([...selected, newConcept]);
    setNewTitle('');
    setAddNew(false);
  };

  const addToConcept = async () => {
    try {
      if (selected.length === 0 && !addNew) {
        return;
      }
      const conceptIds = selected.map((c) => c.id) as number[];

      await API.addLinksToConcepts(
        [...conceptIds],
        [{ url: buildPostUrl(post), title: 'gondola.cc' }],
      );

      onSave();
      const action = () => (
        <button type="button" className="btn">
          <a href={selected.length === 1 ? `/teams/${teamId}/brainstorm/${selected[0].id}` : `/teams/${teamId}/brainstorm`}>
            {selected.length === 1 ? 'View concept' : 'View concepts'}
          </a>
        </button>
      );
      enqueueSnackbar(`Post added to: ${selected.map((c) => c.title).join(', ')}!`, {
        variant: 'success',
        action,
        autoHideDuration: 9000,
      });
    } catch (error) {
      enqueueSnackbar(`Error adding post to concept: ${error}`, {
        variant: 'error',
      });
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (option: Concept) => `${option.title} - ${option.project?.name}`,
  });

  return (
    <Dialog
      open
      onClose={onCancel}
      maxWidth="sm"
      fullWidth
    >
      <div className="absolute right-2 top-2 text-orange">
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="p-4">
        <>
          <h1 className="text-lg text-orange font-bold">
            Save Post to a Concept
          </h1>
          <div>
            <Autocomplete
              multiple
              options={concepts}
              onChange={(_, v) => setSelected(v)}
              value={selected}
              getOptionLabel={(option) => `${option?.title} ${option?.project ? ` - ${option.project.name}` : ''}`}
              filterOptions={filterOptions}
              isOptionEqualToValue={(option, v) => option?.id === v?.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  placeholder="Search for a concept"
                  variant="outlined"
                />
              )}
            />
          </div>
          {addNew && (
            <input
              type="text"
              placeholder="Concept title"
              className="w-full p-2 mt-2 rounded-lg border-2 hover:border-mediumgray"
              onChange={(e) => setNewTitle(e.target.value)}
              value={newTitle}
            />
          )}
          <div>
            <button type="button" onClick={addToConcept} className="btn-primary mt-4" disabled={!selected.length}>Add</button>
            {addNew ? (
              <button type="button" className="btn-primary-outlined ml-4" onClick={saveNewConcept} disabled={!newTitle}>
                Save new concept
              </button>
            ) : (
              <button type="button" className="btn-primary-outlined ml-4" onClick={() => setAddNew(true)}>
                Create new concept
              </button>
            )}
          </div>
        </>
      </div>
    </Dialog>
  );
};

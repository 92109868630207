import PushPin from '@mui/icons-material/PushPin';
import { useHandlePin } from './hooks/useHandlePin';

interface Props {
  postId: number;
  isProfileOwner?: boolean;
  onSuccess?: () => void;
}

export const PinPost = ({
  postId, isProfileOwner, onSuccess,
}: Props) => {
  const onHandlePin = useHandlePin(
    postId,
    true,
    onSuccess,
  );
  if (isProfileOwner) {
    return (
      <button
        type="button"
        className="bg-white hover:bg-orange right-2 top-2 absolute rounded-full p-0.5"
        onClick={onHandlePin}
        aria-label="unpin"
      >
        <PushPin />
      </button>
    );
  }

  return (
    <PushPin
      sx={{ width: 27, height: 27 }}
      className="bg-white right-2 top-2 absolute rounded-full p-0.5"
    />
  );
};

import { MobileTooltip } from 'components/MobileTooltip';
import { NetworkIcon } from 'elements/NetworkIcon';

interface Props {
  account: string;
  networks: number[];
  matchCount: number;
}

export const MatchHeader = ({
  account,
  networks,
  matchCount,
}: Props) => {
  const count = matchCount - 1 > 0 ? ` +${matchCount - 1}` : '';
  const text = `@${account}${count}`;

  return (
    <MobileTooltip title={text} placement="top" arrow>
      <div className="flex items-center overflow-hidden">
        {
          networks.map((network) => <NetworkIcon key={network} network={network} fontSize="small" />)
        }
        <div className="pl-1 text-left font-bold whitespace-nowrap text-ellipsis overflow-hidden">
          {text}
        </div>
      </div>
    </MobileTooltip>
  );
};

import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useApi } from 'lib/contexts/ApplicationState';

export const useHandlePin = (
  postId: number,
  isPinned: boolean,
  onSuccess?: () => void,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const API = useApi();

  const onHandlePin = useCallback(
    async () => {
      if (isPinned) {
        try {
          await API.deletePinnedPost(postId);
          if (onSuccess) onSuccess();
        } catch (err) {
          enqueueSnackbar('Error unpinning post', {
            variant: 'error',
          });
        }
      } else {
        try {
          await API.createPinnedPost(postId);
          if (onSuccess) onSuccess();
        } catch (err) {
          enqueueSnackbar(`${err}`, {
            variant: 'error',
          });
        }
      }
    },
    [API, enqueueSnackbar, isPinned, onSuccess, postId],
  );

  return onHandlePin;
};
